import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Free-hand mouse drawing with D3v6 and React Hooks",
  "description": "Should you use React's built-in mouse handling? Why do coordinates look weird? Why does d3.mouse not work like you expect? Find out with this fun example.",
  "date": "2020-10-06T08:00:00.000Z",
  "published": "2020-10-06T08:00:00.000Z",
  "image": "./mouse-events-with-d3v6-react-hooks.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`A question readers ask a lot is about mouse events and the interaction between React and D3. Should you use React's built-in mouse handling? Why do coordinates look weird? Why does `}<inlineCode parentName="p">{`d3.mouse`}</inlineCode>{` not give what you expect?`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/cfXGYbc.gif",
        "alt": "Freehand drawing with a React app"
      }}></img></p>
    <p>{`With D3v6, `}<inlineCode parentName="p">{`d3.mouse`}</inlineCode>{` becomes `}<inlineCode parentName="p">{`d3.pointer`}</inlineCode>{` and supports touch events natively. No more `}<inlineCode parentName="p">{`d3.mouse`}</inlineCode>{` vs. `}<inlineCode parentName="p">{`d3.touches`}</inlineCode>{` yay, `}<inlineCode parentName="p">{`d3.pointer`}</inlineCode>{` gives you both 🎉`}</p>
    <p>{`And why not use React's built-in mouse handling? Here's why, click the rectangle:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/kind-wing-8tvl8",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`Coordinates from React events don't work with a rotated rectangle. `}<inlineCode parentName="p">{`[204, 328]`}</inlineCode>{` vs `}<inlineCode parentName="p">{`[46, 52]`}</inlineCode>{` with `}<inlineCode parentName="p">{`d3.pointer`}</inlineCode>{`. That's a big difference 🤔`}</p>
    <p>{`You `}<em parentName="p">{`could`}</em>{` figure it out, D3 does after all. We used the same React synthetic event for both sets of coordinates.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function onClick(event) {
  setClickPos([event.pageX, event.pageY])
  setPointerPos(d3.pointer(event))
}
`}</code></pre>
    <p>{`Let me warn you that this is a problem that `}<em parentName="p">{`looks`}</em>{` easy and gets harder and harder the more you look.`}</p>
    <p>{`There's 3 sets of X,Y coordinates on React events alone. Which do you choose? Then there's SVG transformations, relative node positions, all sorts of things.`}</p>
    <p>{`Here's what `}<inlineCode parentName="p">{`d3.pointer`}</inlineCode>{` does to calculate mouse position:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// d3-selection/src/pointer.js

export default function (event, node) {
  event = sourceEvent(event)
  if (node === undefined) node = event.currentTarget
  if (node) {
    var svg = node.ownerSVGElement || node
    if (svg.createSVGPoint) {
      var point = svg.createSVGPoint()
      ;(point.x = event.clientX), (point.y = event.clientY)
      point = point.matrixTransform(node.getScreenCTM().inverse())
      return [point.x, point.y]
    }
    if (node.getBoundingClientRect) {
      var rect = node.getBoundingClientRect()
      return [
        event.clientX - rect.left - node.clientLeft,
        event.clientY - rect.top - node.clientTop,
      ]
    }
  }
  return [event.pageX, event.pageY]
}
`}</code></pre>
    <p>{`🤯`}</p>
    <p>{`There's more to this than you thought. Way more than `}<em parentName="p">{`I`}</em>{` thought ...`}</p>
    <h2 {...{
      "id": "build-a-free-hand-drawing-app-with-react-hooks"
    }}>{`Build a free-hand drawing app with React Hooks`}</h2>
    <p>{`To push D3v6 and React to the limit, we built a free-hand drawing app. Just how fast are modern browsers? How much do you have to optimize? Need you care about performance?`}</p>
    <p>{`See for yourself:`}</p>
    <iframe {...{
      "src": "https://codesandbox.io/embed/mouse-drawing-react-d3-i3y7m",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <p>{`And here's the full build start to finish:`}</p>
    <lite-youtube {...{
      "videoid": "w3X7oyPJbUM",
      "videostartat": "0"
    }}></lite-youtube>
    <lite-youtube {...{
      "videoid": "LQZ_3kUxeKA",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`I'm honestly surprised how well this worked. We're running the code in CodeSandbox in develop mode on top of an entire in-browser IDE `}<em parentName="p">{`and it's smooth as heck`}</em>{`.`}</p>
    <p>{`Sure it struggles when I'm streaming but streaming takes 500% CPU. That means you will `}<strong parentName="p">{`never run into performance issues IRL`}</strong>{`.`}</p>
    <p>{`Build for production, deploy live, and I bet it works on even the cheapest phone.`}</p>
    <p>{`In fact, here it is: `}<a parentName="p" {...{
        "href": "https://csb-i3y7m-d2q0iafbn.vercel.app"
      }}>{`csb-i3y7m-d2q0iafbn.vercel.app`}</a>{`. Give that a shot, tell me how it is. Doesn't work on mobile because touching drags the viewport around instead of drawing ☹️`}</p>
    <p>{`Great on a computer, though`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.imgur.com/cfXGYbc.gif",
        "alt": "Freehand drawing with a React app"
      }}></img></p>
    <h2 {...{
      "id": "heres-how-it-works"
    }}>{`Here's how it works`}</h2>
    <p>{`We start with a large SVG drawing area. Hardcoded to 900 by 900 pixels to avoid dealing with responsiveness.`}</p>
    <pre><code parentName="pre" {...{}}>{`// src/App.js
export default function App() {
    // ...
    <svg width="900px" height="900px">
        <MouseDraw x={0} y={0} width={900} height={900} thickness={thickness} />
    </svg>
`}</code></pre>
    <p>{`That creates and SVG element and renders our `}<inlineCode parentName="p">{`<MouseDraw>`}</inlineCode>{` component. It's gonna do all the work.`}</p>
    <p>{`We added a pen thickness controller on the stream and that's not interesting for this article. Bunch of buttons that set a number 1 to 4. MouseDraw accepts it as a prop.`}</p>
    <h3 {...{
      "id": "the-mousedraw-component"
    }}>{`The `}{`>`}{`MouseDraw> component`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`<MouseDraw>`}</inlineCode>{` component is where the fun happens. It handles mouse events and line rendering.`}</p>
    <p>{`We need 3 pieces of state:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`drawing`}</inlineCode>{` – are we currently drawing a line`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`currentLine`}</inlineCode>{` – the current line being drawn`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`lines`}</inlineCode>{` – the full set of lines on screen`}</li>
    </ul>
    <p>{`Splitting `}<inlineCode parentName="p">{`currentLine`}</inlineCode>{` from `}<inlineCode parentName="p">{`lines`}</inlineCode>{` simplifies our code and means we don't re-render as much. React can see existing lines haven't changed and avoid re-rendering them.`}</p>
    <p><video parentName="p" {...{
        "style": {
          "margin": "auto auto",
          "display": "block",
          "maxWidth": "80%"
        },
        "autoPlay": true,
        "loop": true,
        "muted": true,
        "playsInline": true,
        "loading": "lazy"
      }}>{`
            `}<source parentName="video" {...{
          "src": "https://media4.giphy.com/media/d3mlE7uhX8KFgEmY/giphy-loop.mp4?cid=4ac046a2v2pf4mxodvsqczxrvva7z5tyjolwahz95njjr2u0&rid=giphy-loop.mp4&ct=g",
          "type": "video/mp4"
        }}></source>{`
        `}</video></p>
    <p>{`That's 1 of 2 performance optimizations we did.`}</p>
    <p>{`We add a React ref for our drawing area. We'll use it with a modified D3 Blackbox approach from `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`ReactForDataviz`}</a>{` to give D3 access to React.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
  const [drawing, setDrawing] = useState(false);
  const [currentLine, setCurrentLine] = useState({ thickness, points: [] });
  const [lines, setLines] = useState([]);

  const drawingAreaRef = useRef();
`}</code></pre>
    <p>{`Sets up our state and ref in a new `}<inlineCode parentName="p">{`<MouseDraw>`}</inlineCode>{` component.`}</p>
    <h3 {...{
      "id": "rendering-the-mousedraw-component"
    }}>{`Rendering the `}{`>`}{`MouseDraw> component`}</h3>
    <p>{`We let React handle rendering. Our logic is going to create an array of line objects, each with a thickness and a bunch of `}<inlineCode parentName="p">{`[X, Y]`}</inlineCode>{` points. Hundreds.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
  // ...
  return (
    <g
      transform={\`translate(\${x}, \${y})\`}
      ref={drawingAreaRef}
      onMouseDown={enableDrawing}
      onMouseUp={disableDrawing}
    >
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        style={{ fill: "pink" }}
      />
      {lines.map((line, i) => (
        <Line thickness={line.thickness} points={line.points} key={i} />
      ))}
      <Line thickness={currentLine.thickness} points={currentLine.points} />
    </g>
  )
}
`}</code></pre>
    <p>{`The grouping `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element is like a div, handles our basic positioning via a `}<inlineCode parentName="p">{`transform`}</inlineCode>{`, defines a `}<inlineCode parentName="p">{`ref`}</inlineCode>{` for D3 to hook into, and uses React's mouse up/down events to enable and disable drawing.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<rect>`}</inlineCode>{` element makes our drawing area visible. And it gives the drawing area its size. Without this `}<inlineCode parentName="p">{`<rect>`}</inlineCode>{`, the `}<inlineCode parentName="p">{`<g>`}</inlineCode>{` element would be 0x0 pixels and unclickable.`}</p>
    <p>{`Very important.`}</p>
    <p>{`Inside the grouping element we iterate over all `}<inlineCode parentName="p">{`lines`}</inlineCode>{` and render a `}<inlineCode parentName="p">{`<Line>`}</inlineCode>{` component for each. Then add another for the current line. This one re-renders in full with every mouse move.`}</p>
    <h3 {...{
      "id": "the-mousedraw-business-logic"
    }}>{`The `}{`>`}{`MouseDraw> business logic`}</h3>
    <p>{`The business logic for our free-hand drawing app comes in 4 parts:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`enableDrawing`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`disableDrawing`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`mouseMove`}</inlineCode>{` handling`}</li>
      <li parentName="ul">{`an effect to add a D3 mouse move listener`}</li>
    </ul>
    <p>{`Using a D3 mouse move listener is our 2nd performance optimization. It uses native DOM events instead of React's synthetic approach and works smoother.`}</p>
    <p><strong parentName="p">{`enableDrawing`}</strong>{` is called when you press the mouse button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
    // ...

  function enableDrawing() {
    setCurrentLine({ thickness, points: [] });
    setDrawing(true);
  }
`}</code></pre>
    <p>{`Resets the current line and sets `}<inlineCode parentName="p">{`drawing`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{`. Might have been better as a `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`disableDrawing`}</strong>{` is called when you release the mouse button.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
    // ...

  function disableDrawing() {
    setDrawing(false);
    setLines((lines) => [...lines, currentLine]);
  }
`}</code></pre>
    <p>{`Adds the current line to `}<inlineCode parentName="p">{`lines`}</inlineCode>{` and sets `}<inlineCode parentName="p">{`drawing`}</inlineCode>{` to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. Might have been better as a `}<inlineCode parentName="p">{`useReducer`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`mouseMove`}</strong>{` is a callback that runs every time your mouse moves across the drawing area.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
    // ...

  const mouseMove = useCallback(
    function (event) {
      const [x, y] = d3.pointer(event);
      if (drawing) {
        setCurrentLine((line) => ({
          ...line,
          points: [...line.points, { x, y }]
        }));
      }
    },
    [drawing]
  );
`}</code></pre>
    <p><inlineCode parentName="p">{`useCallback`}</inlineCode>{` lets us memoize this function based on the value of `}<inlineCode parentName="p">{`drawing`}</inlineCode>{`. Means we create a new method every time drawing turns on or off.`}</p>
    <p>{`The method uses `}<inlineCode parentName="p">{`d3.pointer`}</inlineCode>{` to get accurate mouse position and adds a new point to the current line.`}</p>
    <p>{`Yes, hundreds of points per second. Works fine ✌️`}</p>
    <p><strong parentName="p">{`Attaching a D3 mouse listener`}</strong>{` happens in a `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`. Runs when the `}<inlineCode parentName="p">{`mouseMove`}</inlineCode>{` method changes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw.js

export const MouseDraw = ({ x, y, width, height, thickness }) => {
    // ...

  useEffect(() => {
    const area = d3.select(drawingAreaRef.current);
    area.on("mousemove", mouseMove);
    return () => area.on("mousemove", null);
  }, [mouseMove]);
`}</code></pre>
    <p>{`We use a D3 selection to grab the raw DOM node from our `}<inlineCode parentName="p">{`drawingAreaRef`}</inlineCode>{` and turn it into a D3 object. Add a `}<inlineCode parentName="p">{`mousemove`}</inlineCode>{` event listener with `}<inlineCode parentName="p">{`area.on("mousemove" ...`}</inlineCode>{`.`}</p>
    <p>{`And we make sure to return a cleanup function that removes the listener when this effect ends. Helps us avoid stacking function calls that degrade performance.`}</p>
    <p>{`D3 handles calling our `}<inlineCode parentName="p">{`mouseMove`}</inlineCode>{` function on every event.`}</p>
    <h3 {...{
      "id": "drawing-a-line"
    }}>{`Drawing a `}{`>`}{`Line>`}</h3>
    <p>{`We draw every line with a `}<inlineCode parentName="p">{`<Line>`}</inlineCode>{` component that uses D3's line generator. Renders each line as a single `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` SVG element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// src/MouseDraw
const Line = ({ thickness, points }) => {
  const line = useMemo(() => {
    return d3
      .line()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveBasisOpen)
  }, [])

  return (
    <path
      d={line(points)}
      style={{
        stroke: "black",
        strokeWidth: thickness,
        strokeLinejoin: "round",
        strokeLinecap: "round",
        fill: "none",
      }}
    />
  )
}
`}</code></pre>
    <p>{`A curve makes our lines look smoother, calling `}<inlineCode parentName="p">{`useMemo`}</inlineCode>{` ensures we don't re-create the line generator on every render. That's performance optimization no. 3.`}</p>
    <p>{`Render as a `}<inlineCode parentName="p">{`<path>`}</inlineCode>{` element, get the path definition – `}<inlineCode parentName="p">{`d`}</inlineCode>{` – from our line generator, add styling to make the line even smoother. `}<inlineCode parentName="p">{`strokeLinejoin`}</inlineCode>{` avoids jagged edges, `}<inlineCode parentName="p">{`stokeLinecap`}</inlineCode>{` makes line endings round.`}</p>
    <h2 {...{
      "id": "et-voila"
    }}>{`Et voila`}</h2>
    <p>{`And what you get is a smooth free-hand drawing app with D3v6 and React Hooks.`}</p>
    <p><a parentName="p" {...{
        "href": "https://csb-i3y7m-d2q0iafbn.vercel.app"
      }}><img parentName="a" {...{
          "src": "https://i.imgur.com/cfXGYbc.gif",
          "alt": "Freehand drawing with a React app"
        }}></img></a></p>
    <p>{`Key points:`}</p>
    <ul>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`d3.pointer`}</inlineCode>{` for accurate mouse coordinates`}</li>
      <li parentName="ul">{`use D3 events for frequent events`}</li>
      <li parentName="ul">{`use `}<inlineCode parentName="li">{`useMemo`}</inlineCode>{` to avoid recreating D3 objects on every render`}</li>
      <li parentName="ul">{`use as few DOM nodes as possible (1 path per line)`}</li>
      <li parentName="ul">{`change as little data as possible to avoid re-renders`}</li>
      <li parentName="ul">{`React is plenty fast 🤘`}</li>
    </ul>
    <p>{`Wonder if we can make this collaborative with web sockets 🤔`}</p>
    <p>{`Cheers,`}<br />{`
~Swizec`}</p>
    <p>{`PS: if you find this fun, I recommend `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`ReactForDataviz`}</a>{`. It's all about fun examples for lessons about React.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      